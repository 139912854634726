import React from 'react'
import Select from 'react-select/creatable'
import { CreatableSelectContainer } from './styles'

interface Props {
    name: string
    id: string
    isMandatory?: boolean
    label: string
    defaultValue?: any
    [key: string]: any
}
export default function CreatableSelectC({
    id,
    label,
    defaultValue,
    ...rest
}: Props) {
    return (
        <CreatableSelectContainer>
            <div className="creatable-input">
                <Select
                    id={id}
                    placeholder={label}
                    defaultValue={defaultValue}
                    value={defaultValue}
                    classNamePrefix="react-select"
                    {...rest}
                />
            </div>
        </CreatableSelectContainer>
    )
}
