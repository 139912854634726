import styled from 'styled-components'

export const EditMemberContainer = styled.div`
    max-height: 50vh;
    overflow-y: scroll;
    margin-bottom: 7rem;
    margin-top: 2rem;
    &::-webkit-scrollbar {
        width: 0.5rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 0.5rem;
        background: #edeff2;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: 0.5rem;
        background: #c8cacd;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #c8cacd;
    }
    .corporative-infos {
        .company-info-header {
            margin-bottom: 2rem;
        }
        width: 100%;
        background-color: var(--color-light);
        padding: 4rem 3rem 3rem 3rem;
        border-radius: 8px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
        margin-top: 2rem;

        .row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            margin-bottom: 2rem;
            border-bottom: 1px solid #f4f4f4;
            padding-bottom: 2rem;
            .control-button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
        .sub-title {
            font-style: italic;
            margin-bottom: 1rem;
        }
        .row-button {
            margin-bottom: 2rem;
            border-bottom: 1px solid #f4f4f4;
            padding: 0.5rem 0 1rem 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            button {
                width: 10rem;
            }
            .title-values {
                display: flex;
                align-items: center;
                .reference-title {
                    margin-right: 1rem;
                }
            }
        }
        .add-button {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            button {
                width: 25rem;
                padding-left: 0px;
            }
        }
        .add-button-large {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            button {
                width: 42rem;
                padding-left: 0px;
            }
        }
        .select-type {
            padding: 1.8rem 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #f4f4f4;
        }
    }
`
