import React, { createContext, useContext, useState, ReactNode } from 'react'
import { toast } from 'react-toastify'
import { CreateInvite } from '../dtos/create-invite'
import { LoginDto } from '../dtos/login'
import { api } from '../service/api'
import { User } from '../entities/user'

interface AuthContext {
    token?: string
    createInvite(data: CreateInvite): Promise<any | undefined>
    login(data: LoginDto): Promise<number | unknown>
    logout(): void
}

interface Props {
    children: ReactNode
}

const AuthContext = createContext({} as AuthContext)

export function AuthProvider({ children }: Props) {
    // State
    const [token, setToken] = useState<string | undefined>(() => {
        return localStorage.getItem('@ada_admin:token') || undefined
    })

    async function login(data: LoginDto) {
        try {
            const signupData: LoginDto = {
                email: data.email.toLowerCase(),
                password: data.password,
            }
            const response = await api.post<{ token: string; user: User }>(
                '/users/auth',
                signupData
            )
            if (response.data.user.companyInfo[0].systemClearance !== 'ADMIN') {
                return toast.warning(
                    'Apenas administradores podem acessar o portal administrativo'
                )
            }
            localStorage.setItem('@ada_admin:token', response.data.token)
            setToken(response.data.token)
            return response.status
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return error
        }
    }

    async function createInvite(data: CreateInvite) {
        try {
            const response = await api.post('/invites', data, {
                headers: { authorization: `Bearer ${token}` },
            })
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    function logout() {
        setToken(undefined)
        window.location.reload()
        localStorage.removeItem('@ada_admin:token')
    }

    return (
        <AuthContext.Provider
            value={{
                login,
                token,
                logout,
                createInvite,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export function useAuth(): AuthContext {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within a Provider')
    }
    return context
}
