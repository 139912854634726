import styled from 'styled-components'

export const CreatableSelectContainer = styled.div`
    .creatable-input {
        position: relative;
        .css-2b097c-container {
            .css-yk16xz-control,
            .css-1pahdxg-control {
                border-radius: 0.8rem;
                height: 5.5rem;
                min-height: 3.5rem;
                padding: 0.7rem 0.1rem;
                .css-g1d714-ValueContainer {
                }
            }
            .css-26l3qy-menu {
                z-index: 50;
            }
        }
    }
`
