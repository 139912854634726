/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, ReactNode, useContext, useState } from 'react'
import { startOfDay, subYears } from 'date-fns'
import { DateFilter } from '../interfaces/filterBookings'
import { api } from '../service/api'
import { useAuth } from './use-auth'
import { useCompany } from './use-company'
import { Compliance, SpendingMetrics, TripMetrics } from '../entities/analytics'
import { ReportFilter } from '../entities/ReportFilter'
import {
    CarReportUi,
    ExpenseReport,
    FlightReportUi,
    GeneralReportUi,
    HotelReportUi,
} from '../interfaces/Reports'
import { ExpensesCompliance } from '../interfaces/expensesCompliance'
import { ExpensesMetrics } from '../interfaces/expensesMetrics'

interface Props {
    children: ReactNode
}

interface DashboardContextProps {
    dateFilter: DateFilter
    handleFilterDashboard(filter: DateFilter): void
    getDashboardData(boards: string[]): Promise<any>
    handleReportFilter(filter: ReportFilter): void
    getReportData(
        type: 'GENERAL' | 'FLIGHT' | 'HOTEL' | 'CAR' | 'EXPENSES',
        data: ReportFilter | undefined,
        pageSelected: number,
        limit: number
    ): void
    downloadReportData(
        type: 'GENERAL' | 'FLIGHT' | 'HOTEL' | 'CAR' | 'EXPENSES',
        data: ReportFilter | undefined,
        fixedColumns: string[],
        managementInfosColumns: string[]
    ): Promise<string | undefined>
    downloadExpensesReportData(): Promise<string | undefined>
    spendingMetrics?: SpendingMetrics
    tripMetrics?: TripMetrics
    compliance?: Compliance
    reportFilter?: ReportFilter
    expensesReport?: ExpenseReport[]
    generalReport: GeneralReportUi[]
    flightReport: FlightReportUi[]
    hotelReport: HotelReportUi[]
    carReport: CarReportUi[]
    expensesCompliance?: ExpensesCompliance
    expensesMetrics?: ExpensesMetrics
    count?: number
}

const DashboardContext = createContext({} as DashboardContextProps)

export const DashboardProvider = ({ children }: Props) => {
    const { token } = useAuth()
    const { company } = useCompany()

    const [dateFilter, setDateFilter] = useState<DateFilter>({
        typeOfPeriod: 'YEAR',
        period: {
            startDate: startOfDay(subYears(new Date(), 1)),
            endDate: new Date(),
        },
    })

    const [spendingMetrics, setSpendingMetrics] = useState()
    const [tripMetrics, setTripMetrics] = useState<TripMetrics>()
    const [compliance, setCompliance] = useState<Compliance | undefined>()
    const [reportFilter, setReportFilter] = useState<ReportFilter | undefined>({
        typeOfPeriod: 'YEAR',
        period: {
            startDate: startOfDay(subYears(new Date(), 1)),
            endDate: new Date(),
        },
    })
    const [generalReport, setGeneralReport] = useState<GeneralReportUi[]>([])
    const [flightReport, setFlightReport] = useState<FlightReportUi[]>([])
    const [hotelReport, setHotelReport] = useState<HotelReportUi[]>([])
    const [carReport, setCarReport] = useState<CarReportUi[]>([])
    const [expensesReport, setExpensesReport] = useState<ExpenseReport[]>([])
    const [count, setCount] = useState<number>()
    const [expensesCompliance, setExpensesCompliance] = useState<
        ExpensesCompliance | undefined
    >()
    const [expensesMetrics, setExpensesMetrics] = useState<
        ExpensesMetrics | undefined
    >()

    function handleFilterDashboard(filter: DateFilter | undefined) {
        if (filter) {
            setDateFilter(filter)
        }
    }

    function handleReportFilter(filter: ReportFilter) {
        setReportFilter(filter)
    }

    async function getDashboardData(boards: string[]) {
        try {
            if (!company?.id) return undefined
            const response = await api.get('analytics/dashboard', {
                headers: { authorization: `Bearer ${token}` },
                params: {
                    boards,
                    start_date: dateFilter.period.startDate,
                    end_date: dateFilter.period.endDate,
                    company_id: company?.id,
                },
            })
            setSpendingMetrics(response.data.spendingMetrics || undefined)
            setTripMetrics(response.data.tripMetrics || undefined)
            setCompliance(response.data.compliance || undefined)
            setExpensesCompliance(response.data.expensesCompliance || undefined)
            setExpensesMetrics(response.data.expenses || undefined)
            return response.data
        } catch (error) {
            return undefined
        }
    }

    async function getReportData(
        type: 'GENERAL' | 'FLIGHT' | 'HOTEL' | 'CAR' | 'EXPENSES',
        data: ReportFilter | undefined,
        pageSelected: number,
        limit: number
    ) {
        try {
            if (type === 'EXPENSES') {
                const response = await api.get(
                    `analytics/reports/${type.toLowerCase()}`,
                    {
                        headers: { authorization: `Bearer ${token}` },
                        params: {
                            company_id: company?.id,
                            start_date: data?.period?.startDate,
                            end_date: data?.period?.endDate,
                            limit,
                            offset: limit * (pageSelected - 1),
                        },
                    }
                )
                setExpensesReport(response.data.expenses)
                setCount(response.data.count)
                return response.data
            }

            const response = await api.get(
                `analytics/reports/${type.toLowerCase()}`,
                {
                    headers: { authorization: `Bearer ${token}` },
                    params: {
                        company_id: company?.id,
                        start_date: data?.period?.startDate,
                        end_date: data?.period?.endDate,
                        management_info_options: data?.options,
                        limit,
                        page: pageSelected,
                    },
                }
            )
            setCount(response.data.count)
            if (type === 'GENERAL') setGeneralReport(response.data.items)
            if (type === 'FLIGHT') setFlightReport(response.data.items)
            if (type === 'HOTEL') setHotelReport(response.data.items)
            if (type === 'CAR') setCarReport(response.data.items)

            return response.data
        } catch (error) {
            return undefined
        }
    }

    async function downloadReportData(
        type: 'GENERAL' | 'FLIGHT' | 'HOTEL' | 'CAR' | 'EXPENSES',
        data: ReportFilter | undefined,
        fixedColumns: string[],
        managementInfosColumns: string[]
    ) {
        try {
            const response = await api.post(
                `analytics/reports/${type.toLowerCase()}/csv`,
                {
                    filters: {
                        companyId: company?.id,
                        startDate: data?.period?.startDate,
                        endDate: data?.period?.endDate,
                        managementInfoOptions: data?.options,
                    },
                    columns: {
                        fixed: fixedColumns,
                        managementInfo: managementInfosColumns,
                    },
                },
                {
                    headers: { authorization: `Bearer ${token}` },
                }
            )
            window.open(response.data)

            return response.data
        } catch (error) {
            return undefined
        }
    }

    async function downloadExpensesReportData() {
        try {
            const response = await api.post(
                `analytics/reports/expenses/csv`,
                {
                    companyId: company?.id,
                    startDate: reportFilter?.period?.startDate,
                    endDate: reportFilter?.period?.endDate,
                },
                {
                    headers: { authorization: `Bearer ${token}` },
                }
            )
            window.open(response.data)

            return response.data
        } catch (error) {
            return undefined
        }
    }

    return (
        <DashboardContext.Provider
            value={{
                handleFilterDashboard,
                dateFilter,
                getDashboardData,
                downloadReportData,
                downloadExpensesReportData,
                tripMetrics,
                compliance,
                spendingMetrics,
                handleReportFilter,
                reportFilter,
                getReportData,
                carReport,
                flightReport,
                expensesReport,
                generalReport,
                hotelReport,
                expensesCompliance,
                expensesMetrics,
                count,
            }}
        >
            {children}
        </DashboardContext.Provider>
    )
}

export const useDashboard = (): DashboardContextProps => {
    const context = useContext(DashboardContext)
    return context
}
