import styled from 'styled-components'

export const ExpenseCardContainer = styled.div`
    width: 100%;
    padding: 1.5rem 2rem 1.5rem 2rem;
    display: grid;
    grid-template-columns: 2fr 2.5fr 0.5fr 1.5fr 3fr;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0.8rem;
    .price {
        text-align: center;
    }
    .out-of-policy {
        color: #e8604a;
        svg {
            color: #e8604a;
            height: 2.2rem;
            width: 2.2rem;
        }
    }
    .description {
        display: flex;
        gap: 2rem;
        .observations {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    .action-btns {
        display: flex;
        gap: 1.2rem;
        .see-more-btn {
            display: flex;
            align-items: center;
            padding: 0 1.2rem;
            svg {
                height: 2.4rem;
                width: 2.4rem;
                color: #96989b;
            }
            background-color: #fff;
            border: 1px solid #c8cacd;
            border-radius: 0.8rem;
        }
    }
`
