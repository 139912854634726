import styled from 'styled-components'

export const InputContainer = styled.div`
    .error-message {
        text-align: start;
        font-size: 1.3rem;
        color: var(--color-error);
        margin: 0;
    }

    .error {
        color: var(--color-error);
    }
    .normal-label {
        margin-bottom: 1rem;
    }
`
export const InputContent = styled.button`
    text-align: start;
    padding-left: 1rem;
    position: relative;
    width: 100%;
    min-width: 20rem;
    font-size: 1.6rem;
    position: relative;
    height: 5.5rem;
    overflow: hidden;
    border: 1px solid var(--color-light-gray);
    border-radius: 0.8rem;
    background-color: white;
    svg {
        position: absolute;
        top: 50%;
        width: 2rem;
        height: 2rem;
        color: var(--color-secondary-gray);
        transform: translate(50%, -50%);
    }

    .input-padding {
        padding-top: 1rem;
    }
    .label-name {
        position: absolute;
        bottom: 25%;
        left: 3.5rem;
        width: 100%;
        height: 100%;
        pointer-events: none;

        &::after {
            content: '';
            position: absolute;
            left: 0px;
            bottom: -1px;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            transition: transform 0.3s ease;
        }
        .content-name {
            position: absolute;
            bottom: 0.5rem;
            left: 0px;
            color: var(--color-gray);
            transition: all 0.3s ease;
        }
    }
`
