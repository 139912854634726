/* eslint-disable no-param-reassign */
import React, { ReactNode, createContext, useContext, useState } from 'react'
import { startOfDay, subYears } from 'date-fns'
import { toast } from 'react-toastify'
import { api } from '../service/api'
import { useAuth } from './use-auth'
import { Expenses } from '../interfaces/expenses'
import { AdvanceMoney } from '../interfaces/advanced-money'
import { useCompany } from './use-company'
import { ExpensePaymentMethod } from '../enums/expense-payment-method.enum'
import {
    ExpenseCategoryPolicy,
    ExpensesCategory,
} from '../entities/expenses-category'
import { ExpenseStatus } from '../enums/expenses-status.enum'
import { ApproversPerLevelDto } from '../interfaces/approvers-per-level'
import { DateFilter } from '../interfaces/filterBookings'
// import { api } from '../service/api'

interface ExpensesProps {
    children: ReactNode
}

interface ExpensesContextProps {
    expensesDateFilter?: DateFilter
    handleExpensesDateFilter(filter: DateFilter): void
    openModal: Expenses | undefined
    openAdvancedMoneyModal: AdvanceMoney | undefined
    openModalHandler(data: Expenses | undefined): void
    openAdvancedMoneyModalHandler(data: AdvanceMoney | undefined): void
    completeExpenses(id: string, date?: Date): Promise<Expenses | undefined>
    completeAdvanceMoney(
        id: string,
        date?: Date
    ): Promise<AdvanceMoney | undefined>
    getExpenses: (
        type: ExpenseStatus,
        creatorId: string | undefined,
        numberIdentifier: string | undefined,
        paymentMethods: ExpensePaymentMethod[] | undefined,
        offset: number,
        limit: number,
        companyId: string,
        creditCardId?: string
    ) => Promise<{ count: number; expenses: Expenses[] } | undefined>
    getAdvancedMoney: (
        type: 'COMPLETED' | 'CONFIRMED' | 'RETURNED',
        creatorId: string | undefined,
        numberIdentifier: string | undefined,
        offset: number,
        limit: number
    ) => Promise<{ count: number; advanceMoney: AdvanceMoney[] } | undefined>
    openHistoryModal(history: Expenses['history'] | undefined): void
    openQrCodeDataModal(history: Expenses['qrCodeData'] | undefined): void
    openOcrDataModal(history: Expenses['ocrData'] | undefined): void
    openUseHistoryModal(advanceMoney: AdvanceMoney | undefined): void
    createExpenseCategories: (
        label: string
    ) => Promise<ExpensesCategory | undefined>
    completeExpensesInBatch(expenseIds: string[]): Promise<any | undefined>
    editExpenseCategories: ({
        id,
        label,
        active,
        policies,
    }: {
        id: string
        label?: string
        active?: boolean
        policies?: ExpenseCategoryPolicy[]
    }) => Promise<ExpensesCategory | undefined>
    changeExpenseCategory(data: ExpensesCategory | undefined): void
    openEditExpenseCategory: ExpensesCategory | undefined
    getCompanyExpenseCategories: () => Promise<
        { expenseCategories: ExpensesCategory[]; count: number } | undefined
    >
    openApprovalStagesModal(
        approvalStages: Expenses['approvalStages'] | undefined
    ): void
    expenseCategories: ExpensesCategory[]
    isHistoryModalOpen: Expenses['history'] | undefined
    isQrCodeDataModalOpen: Expenses['qrCodeData'] | undefined
    isOcrDataModalOpen: Expenses['ocrData'] | undefined
    isUseHistoryModal?: AdvanceMoney
    expenses: Expenses[]
    advancedMoney: AdvanceMoney[]
    isApprovalStagesModalOpen: Expenses['approvalStages'] | undefined
    count: number
    getApproversOfExpense(
        id: string
    ): Promise<ApproversPerLevelDto[] | undefined>
    approversOfExpenses: ApproversPerLevelDto[]
}

const ExpensesContext = createContext({} as ExpensesContextProps)

export const ExpensesProvider = ({ children }: ExpensesProps) => {
    const { token } = useAuth()
    const { company } = useCompany()
    const [openModal, setOpenModal] = useState<Expenses | undefined>()
    const [expenses, setExpenses] = useState<Expenses[]>([])
    const [advancedMoney, setAdvancedMoney] = useState<AdvanceMoney[]>([])
    const [expenseCategories, setExpenseCategories] = useState<
        ExpensesCategory[]
    >([])
    const [openAdvancedMoneyModal, setOpenAdvancedMoneyModal] = useState<
        AdvanceMoney | undefined
    >()
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState<
        Expenses['history'] | undefined
    >()
    const [isQrCodeDataModalOpen, setIsQrCodeDataModalOpen] = useState<
        Expenses['qrCodeData'] | undefined
    >()
    const [isOcrDataModalOpen, setIsOcrDataModalOpen] = useState<
        Expenses['ocrData'] | undefined
    >()
    const [isApprovalStagesModalOpen, setIsApprovalStagesModalOpen] = useState<
        Expenses['approvalStages'] | undefined
    >()
    const [isUseHistoryModal, setIsUseHistoryModal] = useState<AdvanceMoney>()
    const [count, setCount] = useState(0)
    const [openEditExpenseCategory, setOpenEditExpenseCategory] = useState<
        ExpensesCategory | undefined
    >()
    const [approversOfExpenses, setApproversOfExpenses] = useState<
        ApproversPerLevelDto[]
    >([])
    const [expensesDateFilter, setExpensesDateFilter] = useState<
        DateFilter | undefined
    >({
        typeOfPeriod: 'YEAR',
        period: {
            startDate: startOfDay(subYears(new Date(), 1)),
            endDate: new Date(),
        },
    })

    function handleExpensesDateFilter(filter: DateFilter) {
        setExpensesDateFilter(filter)
    }

    function changeExpenseCategory(data: ExpensesCategory | undefined) {
        setOpenEditExpenseCategory(data)
    }

    function openModalHandler(data: Expenses | undefined) {
        setOpenModal(data)
    }

    function openAdvancedMoneyModalHandler(data: AdvanceMoney | undefined) {
        setOpenAdvancedMoneyModal(data)
    }

    async function getExpenses(
        type: ExpenseStatus,
        creatorId: string | undefined,
        numberIdentifier: string | undefined,
        paymentMethods: ExpensePaymentMethod[] | undefined,
        offset: number,
        limit: number,
        companyId: string,
        creditCardId?: string
    ) {
        try {
            if (numberIdentifier === '') numberIdentifier = undefined
            const response = await api.get<{
                count: number
                expenses: Expenses[]
            }>(`/expenses/company`, {
                params: {
                    limit,
                    offset,
                    status: type,
                    creator_id: creatorId,
                    payment_methods: paymentMethods,
                    number_identifier: numberIdentifier || undefined,
                    companyId,
                    start_date: expensesDateFilter?.period.startDate,
                    end_date: expensesDateFilter?.period.endDate,
                    credit_card_id: creditCardId,
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            setExpenses(response.data.expenses)
            setCount(response.data.count)
            return response.data
        } catch (error) {
            return undefined
        }
    }

    async function getAdvancedMoney(
        type: 'COMPLETED' | 'CONFIRMED' | 'RETURNED',
        creatorId: string | undefined,
        numberIdentifier: string | undefined,
        offset: number,
        limit: number
    ) {
        try {
            const response = await api.get<{
                count: number
                advanceMoney: AdvanceMoney[]
            }>('advance-money/company', {
                params: {
                    limit,
                    creator_id: creatorId,
                    number_identifier: numberIdentifier || undefined,
                    offset,
                    status: type,
                    companyId: company?.id || '',
                },
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            setAdvancedMoney(response.data.advanceMoney)
            setCount(response.data.count)
            return response.data
        } catch (error) {
            return undefined
        }
    }

    async function completeExpenses(id: string, date?: Date) {
        try {
            const response = await api.patch<Expenses>(
                `/expenses/${id}/complete`,
                {
                    expectedPaymentDate: date ? startOfDay(date) : undefined,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            setExpenses((prev) => {
                const index = prev.findIndex((expense) => expense.id === id)
                if (index !== undefined) {
                    prev.splice(index, 1)
                }
                return [...prev]
            })
            return response.data
        } catch (error) {
            return undefined
        }
    }

    async function getApproversOfExpense(id: string) {
        try {
            const response = await api.get<ApproversPerLevelDto[]>(
                `/expenses/${id}/approvers`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            setApproversOfExpenses([...response.data])
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return error
        }
    }

    async function completeExpensesInBatch(expenseIds: string[]) {
        try {
            const response = await api.patch<Expenses>(
                `/expenses/complete-in-batch`,
                {
                    expenseIds,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            if (response.status === 200) {
                setExpenses((prev) => {
                    return [
                        ...prev.filter(
                            (expense) => !expenseIds.includes(expense.id)
                        ),
                    ]
                })
                toast.success(
                    `${
                        expenseIds.length === 1
                            ? '1 despesa conciliada com sucesso'
                            : `${expenseIds.length} despesas conciliadas com sucesso`
                    }`
                )
                return true
            }
            toast.warning('Erro ao conciliar despesas')
            return false
        } catch (error) {
            return undefined
        }
    }

    async function completeAdvanceMoney(id: string, date?: Date) {
        try {
            const response = await api.patch<AdvanceMoney>(
                `/advance-money/${id}/complete`,
                {
                    expectedPaymentDate: date ? startOfDay(date) : undefined,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
            setAdvancedMoney((prev) => {
                const index = prev.findIndex(
                    (advanceMoney) => advanceMoney.id === id
                )
                if (index !== undefined) {
                    prev.splice(index, 1)
                }
                return [...prev]
            })
            return response.data
        } catch (error) {
            return undefined
        }
    }

    async function getCompanyExpenseCategories() {
        try {
            const response = await api.get<{
                expenseCategories: ExpensesCategory[]
                count: number
            }>(`/expense-categories/company/${company?.id}`, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
                params: {
                    companyId: company?.id || '',
                },
            })
            setExpenseCategories(response.data.expenseCategories)
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return error
        }
    }

    async function editExpenseCategories({
        id,
        label,
        active,
        policies,
    }: {
        id: string
        label?: string
        active?: boolean
        policies?: ExpenseCategoryPolicy[]
    }) {
        try {
            const response = await api.patch<ExpensesCategory>(
                `/expense-categories/${id}`,
                {
                    label,
                    active,
                    policies: policies?.map((policy) => ({
                        travelerClearanceId: policy.travelerClearanceId,
                        enabled: policy.enabled,
                        maxAmountPerDay: policy.maxAmountPerDay,
                        maxAmountPerItem: policy.maxAmountPerItem,
                        maxItemsPerDay: policy.maxItemsPerDay,
                    })),
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                    params: {
                        companyId: company?.id || '',
                    },
                }
            )
            setExpenseCategories((prev) => {
                const index = prev.findIndex((category) => category.id === id)
                if (index !== undefined) {
                    prev[index] = response.data
                }
                return [...prev]
            })

            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    async function createExpenseCategories(label: string) {
        try {
            const response = await api.post<ExpensesCategory>(
                `/expense-categories`,
                {
                    label,
                    active: true,
                    companyId: company?.id || '',
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                    params: {
                        companyId: company?.id || '',
                    },
                }
            )
            setExpenseCategories([response.data, ...expenseCategories])
            return response.data
        } catch (error: any) {
            const e: string = error.response.data.details.pt
            toast.warning(e)
            return undefined
        }
    }

    function openHistoryModal(history: Expenses['history'] | undefined) {
        setIsHistoryModalOpen(history)
    }

    function openQrCodeDataModal(
        qrCodeData: Expenses['qrCodeData'] | undefined
    ) {
        setIsQrCodeDataModalOpen(qrCodeData)
    }

    function openOcrDataModal(ocrData: Expenses['ocrData'] | undefined) {
        setIsOcrDataModalOpen(ocrData)
    }

    function openApprovalStagesModal(
        approvalStages: Expenses['approvalStages'] | undefined
    ) {
        setIsApprovalStagesModalOpen(approvalStages)
    }

    function openUseHistoryModal(advanceMoney: AdvanceMoney | undefined) {
        setIsUseHistoryModal(advanceMoney)
    }

    return (
        <ExpensesContext.Provider
            value={{
                openModal,
                isUseHistoryModal,
                openUseHistoryModal,
                openModalHandler,
                getExpenses,
                expenses,
                count,
                completeExpenses,
                advancedMoney,
                getAdvancedMoney,
                openAdvancedMoneyModalHandler,
                openAdvancedMoneyModal,
                completeAdvanceMoney,
                isHistoryModalOpen,
                openEditExpenseCategory,
                changeExpenseCategory,
                openHistoryModal,
                createExpenseCategories,
                editExpenseCategories,
                expenseCategories,
                getCompanyExpenseCategories,
                completeExpensesInBatch,
                isApprovalStagesModalOpen,
                openApprovalStagesModal,
                isOcrDataModalOpen,
                isQrCodeDataModalOpen,
                openOcrDataModal,
                openQrCodeDataModal,
                approversOfExpenses,
                getApproversOfExpense,
                expensesDateFilter,
                handleExpensesDateFilter,
            }}
        >
            {children}
        </ExpensesContext.Provider>
    )
}

export function useExpenses(): ExpensesContextProps {
    const context = useContext(ExpensesContext)
    return context
}
