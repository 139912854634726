/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react'
import ModalContainer from 'react-modal'
import { TextField } from '@material-ui/core'
import { toast } from 'react-toastify'
import { ExpensesModalContainer } from './styles'
import { Button } from '../../components/Button'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    callback: (date?: Date) => void
}

export default function ConfirmPaymentModal({
    isOpen,
    onRequestClose,
    callback,
}: Props) {
    const [date, setDate] = useState('')

    const handleDateChange = (event: any) => {
        let { value } = event.target

        // Only allow numbers and limit the length to 10 characters (dd/mm/yyyy)
        value = value.replace(/[^\d]/g, '').substring(0, 8)

        // Automatically add slashes
        if (value.length > 2) {
            value = `${value.substring(0, 2)}/${value.substring(2)}`
        }
        if (value.length > 5) {
            value = `${value.substring(0, 5)}/${value.substring(5)}`
        }

        setDate(value)
    }

    async function handleConfirm() {
        if (date) {
            const [day, month, year] = date.split('/')
            const newDate = new Date(+year, +month - 1, +day)
            if (newDate < new Date()) {
                return toast.warning('Data de pagamento inválida')
            }
            if (!Number(day) || !Number(month) || !Number(year)) {
                return toast.warning('Data de pagamento inválida')
            }
            if (
                new Date(date).setHours(0, 0, 0).toLocaleString() ===
                new Date().setHours(0, 0, 0).toLocaleString()
            ) {
                return toast.warning('Data de pagamento inválida')
            }
            const currentYear = new Date(
                Number(`20${year}`),
                Number(`${Number(month) - 1}`)
            )
            if (currentYear < new Date()) {
                return toast.warning('Data de pagamento inválida')
            }
            if (Number(year) < 1900) {
                return toast.warning('Data de pagamento inválida')
            }
            if (month.length !== 2) {
                return toast.warning('Mês da Data de pagamento inválido.')
            }
            if (Number(month) > 12 || Number(month) < 1) {
                return toast.warning('Mês da Data de pagamento inválido.')
            }
            if (Number(day) > 31 || Number(day) < 1) {
                return toast.warning('Dia da Data de pagamento inválido.')
            }
            if (year.length !== 4) {
                return toast.warning('Ano da Data de pagamento inválido.')
            }
            await callback(newDate)
            onRequestClose()
        } else {
            await callback()
            onRequestClose()
        }
        return undefined
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-expenses-future-payment"
        >
            <ExpensesModalContainer>
                <div className="header">
                    <h2 className="f18-700-dark">Confirmação de pagamento</h2>
                    <TextField
                        style={{ marginTop: '2rem', width: '100%' }}
                        name="date"
                        label="Data de pagamento previsto (opcional)"
                        value={date}
                        onChange={handleDateChange}
                        placeholder="dd/mm/yyyy"
                        InputLabelProps={{
                            style: { fontSize: '2rem' }, // Increase label size
                            shrink: true,
                        }}
                        inputProps={{
                            style: { fontSize: '1.5rem' }, // Increase input text size
                        }}
                    />
                </div>
                <div className="btn">
                    <Button
                        buttonType="BORDERED"
                        color="PRIMARY"
                        onClick={onRequestClose}
                        text="Cancelar"
                    />
                    <Button
                        buttonType="FILLED"
                        color="PRIMARY"
                        onClick={() => handleConfirm()}
                        text="Confirmar"
                    />
                </div>
            </ExpensesModalContainer>
        </ModalContainer>
    )
}
