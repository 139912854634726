/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react'
import ModalContainer from 'react-modal'
import { FaArrowRight } from 'react-icons/fa'
import { GiShieldDisabled } from 'react-icons/gi'
import { ExpensesModalContainer } from './styles'
import { expensesDateFormatter } from '../../utils/expensnes/dateFormatter'
import {
    expenseTypeIcon,
    expensesIconHandler,
} from '../../utils/expensnes/ExpensesIconsHandler'
import { priceFormatter } from '../../utils/expensnes/priceFormatter'
import { Expenses } from '../../interfaces/expenses'
import { useExpenses } from '../../hooks/use-expenses'
import { ExpensePaymentMethod } from '../../enums/expense-payment-method.enum'
import { ListMembers } from '../ListMembers'
import { expenseOutOfPolicyHandler } from '../../utils/expensnes/expenseOutOfPolicyHandler'
import { LabelTooltip } from '../../components/TitleDescription/LabelTooltip'

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    expense: Expenses
}

export default function ExpensesModal({
    isOpen,
    onRequestClose,
    expense,
}: Props) {
    const {
        openHistoryModal,
        openApprovalStagesModal,
        openQrCodeDataModal,
        openOcrDataModal,
    } = useExpenses()
    const [showAllTravels, setShowAllTravels] = useState(false)
    function paymentLabel(payment: ExpensePaymentMethod) {
        if (payment === ExpensePaymentMethod.CASH) {
            return 'Dinheiro'
        }
        if (payment === ExpensePaymentMethod.ADVANCE_MONEY) {
            return 'Adiantamento'
        }
        if (payment === ExpensePaymentMethod.NON_REFUNDABLE) {
            return 'Não reembolsável'
        }
        return '-'
    }
    function typeLabel(type: string) {
        if (type === 'MANUAL_INVOICE') {
            return 'Inserção de dados manual'
        }
        return 'Cupom fiscal escaneado'
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-expenses"
        >
            {expense.type === 'MILEAGE' ? (
                <ExpensesModalContainer>
                    <div className="header">
                        <div className="description">
                            <div className="image">
                                <img
                                    src={expenseTypeIcon('MILEAGE')}
                                    alt="expense-image"
                                />
                            </div>
                            <div className="observations">
                                <div>
                                    <h3 className="f16-500-dark">
                                        {`Despesa ${
                                            expense.numberIdentifier
                                                ? `${expense.numberIdentifier}`
                                                : ''
                                        }`}
                                    </h3>
                                </div>
                                <p className="f14-500-gray">
                                    {expensesDateFormatter(
                                        new Date(
                                            expense.info.departureDateTime
                                        ).toDateString()
                                    )}
                                    {expense.info.returnDateTime &&
                                        ` -
                                    ${expensesDateFormatter(
                                        new Date(
                                            expense.info.returnDateTime
                                        ).toDateString()
                                    )}`}
                                </p>
                                <button
                                    type="button"
                                    className="history-btn"
                                    onClick={() =>
                                        openHistoryModal(expense.history)
                                    }
                                >
                                    Ver histórico
                                </button>
                                <div>
                                    <button
                                        type="button"
                                        className="history-btn"
                                        onClick={() =>
                                            openApprovalStagesModal(
                                                expense.approvalStages
                                            )
                                        }
                                    >
                                        Ver estágios aprovação
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="price-date">
                            <div className="out-of-policy">
                                {expenseOutOfPolicyHandler(
                                    expense.travelerClearanceEvaluation
                                ).isOutOfPolicy ? (
                                    <GiShieldDisabled />
                                ) : (
                                    <div />
                                )}
                            </div>
                            <h3 className="f22-700-dark">
                                {`R$${priceFormatter(
                                    expense.info.amountDue || 0
                                )}`}
                            </h3>
                        </div>
                    </div>
                    <div className="content">
                        <div className="content-block">
                            <p className="f16-500-gray italic">
                                Informações gerenciais
                            </p>
                            <div className="container">
                                <div className="label-value">
                                    <p className="f16-500-gray">Viagem</p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            {expense.trip?.label || '-'}
                                        </p>
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">
                                        Participantes
                                    </p>
                                    <div className="value">
                                        <div className="travelers">
                                            <p className="f16-500-dark">
                                                {`${
                                                    expense.travelers.find(
                                                        (traveler) =>
                                                            traveler.id ===
                                                            expense.createdBy
                                                    )?.firstName || ''
                                                } ${
                                                    expense.travelers.find(
                                                        (traveler) =>
                                                            traveler.id ===
                                                            expense.createdBy
                                                    )?.lastName || ''
                                                }`}
                                            </p>
                                            {expense.travelers.length > 1 && (
                                                <p>
                                                    +
                                                    {expense.travelers.length -
                                                        1}
                                                </p>
                                            )}
                                        </div>
                                        {expense.travelers.length > 1 && (
                                            <div className="see-all">
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        setShowAllTravels(true)
                                                    }
                                                >
                                                    ver todos
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">
                                        Tipo de despesa
                                    </p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            Quilometragem
                                        </p>
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">Pagamento</p>
                                    <div className="value">
                                        {expense.payment.method ===
                                        'ADVANCE_MONEY' ? (
                                            <p className="f16-500-dark">
                                                {`${paymentLabel(
                                                    expense.payment.method
                                                )}  #${
                                                    expense.payment.advanceMoney
                                                        ?.numberIdentifier || ''
                                                }`}
                                            </p>
                                        ) : (
                                            <p className="f16-500-dark">
                                                {paymentLabel(
                                                    expense.payment.method
                                                )}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                {expense.payment.creditCard && (
                                    <div className="label-value">
                                        <p className="f16-500-gray">
                                            Cartão utilizado
                                        </p>
                                        <div className="value">
                                            <p className="f16-500-dark">
                                                {expense.payment.creditCard
                                                    .label || '-'}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <div className="label-value">
                                    <p className="f16-500-gray">
                                        Método de cadastro
                                    </p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            Inserção de dados
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-block">
                            <p className="f16-500-gray italic">
                                Informações sobre a despesa
                            </p>
                            <div className="container">
                                <div className="label-value">
                                    <p className="f16-500-gray">
                                        Local de partida
                                    </p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            {
                                                expense.info.departure
                                                    ?.addressLine
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">Destino</p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            {
                                                expense.info.destination
                                                    ?.addressLine
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">Data</p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            {expensesDateFormatter(
                                                new Date(
                                                    expense.info.departureDateTime
                                                ).toDateString()
                                            )}
                                            {expense.info.returnDateTime &&
                                                ` -
                                    ${expensesDateFormatter(
                                        new Date(
                                            expense.info.returnDateTime
                                        ).toDateString()
                                    )}`}
                                        </p>
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">
                                        Quilometragem
                                    </p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            {expense.info.distance?.toFixed(2)}
                                            km
                                        </p>
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">
                                        Valor da despesa
                                    </p>
                                    <div className="value">
                                        <p className="f16-500-dark">{`R$${priceFormatter(
                                            expense.info.amountDue || 0
                                        )}`}</p>
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">Categoria</p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            {expense.info.expenseCategory
                                                ?.label || ''}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {expense.info.waypoints &&
                        expense.info.waypoints.length > 0 && (
                            <div className="comments">
                                <p className="f16-500-gray">
                                    Percurso completo:
                                </p>
                                <div className="waypoints-row">
                                    <p className="f14-500-gray">
                                        {expense.info.departure?.addressLine}
                                    </p>
                                    <FaArrowRight fontSize={10} />
                                    {expense.info.waypoints.map(
                                        (waypoint, index) => (
                                            <>
                                                <p
                                                    className="f14-500-gray"
                                                    key={index}
                                                >
                                                    {waypoint.addressLine}
                                                </p>
                                                <FaArrowRight fontSize={10} />
                                            </>
                                        )
                                    )}
                                    <p className="f14-500-gray">
                                        {expense.info.destination?.addressLine}
                                    </p>
                                </div>
                            </div>
                        )}
                    {expenseOutOfPolicyHandler(
                        expense.travelerClearanceEvaluation
                    ).isOutOfPolicy && (
                        <div className="comments">
                            <p className="f16-500-gray">
                                Quebra de política nos seguintes tópicos:
                            </p>
                            <div className="out-of-policy">
                                {expenseOutOfPolicyHandler(
                                    expense.travelerClearanceEvaluation
                                ).messages.map((message, index) => (
                                    <li
                                        className="out-of-policy-message"
                                        key={index}
                                    >
                                        {message}
                                    </li>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className="comments">
                        <p className="f16-500-gray">Informações gerenciais:</p>
                        <div className="management-info">
                            {expense.managementInfo &&
                            expense.managementInfo.length > 0 ? (
                                expense.managementInfo.map(
                                    (management, index) => (
                                        <div className="label-value-m">
                                            <p className="f16-500-dark">
                                                {management.label}
                                            </p>
                                            <div className="value flex-wrap">
                                                {management.options.map(
                                                    (option) => (
                                                        <p
                                                            className="f14-500-gray"
                                                            key={index}
                                                        >
                                                            {option.label}
                                                        </p>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )
                                )
                            ) : (
                                <p className="f14-500-gray">
                                    Sem informações gerencias vinculadas
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="comments">
                        <p className="f16-500-gray">Descrição</p>
                        <div className="description">
                            <p className="f16-500-gray">
                                {expense.observations || 'Sem observações'}
                            </p>
                        </div>
                    </div>
                </ExpensesModalContainer>
            ) : (
                <ExpensesModalContainer>
                    <div className="header">
                        <div className="description">
                            <div className="image">
                                <img
                                    src={expensesIconHandler(expense.info.type)}
                                    alt="expense-image"
                                />
                            </div>
                            <div className="observations">
                                <div>
                                    <h3 className="f16-500-dark">
                                        {`Despesa ${
                                            expense.numberIdentifier
                                                ? `${expense.numberIdentifier}`
                                                : ''
                                        } • ${
                                            expense.info.expenseCategory
                                                ?.label || ''
                                        }`}
                                    </h3>
                                </div>
                                <p className="f14-500-gray">
                                    {expensesDateFormatter(
                                        new Date(
                                            expense.datetime
                                        ).toDateString()
                                    )}
                                </p>
                                <button
                                    type="button"
                                    className="history-btn"
                                    onClick={() =>
                                        openHistoryModal(expense.history)
                                    }
                                >
                                    Ver histórico
                                </button>
                                <div>
                                    <button
                                        type="button"
                                        className="history-btn"
                                        onClick={() =>
                                            openApprovalStagesModal(
                                                expense.approvalStages
                                            )
                                        }
                                    >
                                        Ver estágios aprovação
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="price-date">
                            <div className="out-of-policy">
                                {expenseOutOfPolicyHandler(
                                    expense.travelerClearanceEvaluation
                                ).isOutOfPolicy ? (
                                    <GiShieldDisabled />
                                ) : (
                                    <div />
                                )}
                            </div>
                            <h3 className="f22-700-dark">
                                {`R$${priceFormatter(
                                    expense.info.amount || 0
                                )}`}
                            </h3>
                        </div>
                    </div>
                    <div className="content">
                        <div className="content-block">
                            <p className="f16-500-gray italic">
                                Informações gerenciais
                            </p>
                            <div className="container">
                                <div className="label-value">
                                    <p className="f16-500-gray">Viagem</p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            {expense.trip?.label || '-'}
                                        </p>
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">
                                        Participantes
                                    </p>
                                    <div className="value">
                                        <div className="travelers">
                                            <p className="f16-500-dark">
                                                {`${
                                                    expense.travelers.find(
                                                        (traveler) =>
                                                            traveler.id ===
                                                            expense.createdBy
                                                    )?.firstName || ''
                                                } ${
                                                    expense.travelers.find(
                                                        (traveler) =>
                                                            traveler.id ===
                                                            expense.createdBy
                                                    )?.lastName || ''
                                                }`}
                                            </p>
                                            {expense.travelers.length > 1 && (
                                                <p>
                                                    +
                                                    {expense.travelers.length -
                                                        1}
                                                </p>
                                            )}
                                        </div>
                                        {expense.travelers.length > 1 && (
                                            <div className="see-all">
                                                <button
                                                    type="button"
                                                    onClick={() =>
                                                        setShowAllTravels(true)
                                                    }
                                                >
                                                    ver todos
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">Pagamento</p>
                                    <div className="value">
                                        {expense.payment.method ===
                                        'ADVANCE_MONEY' ? (
                                            <p className="f16-500-dark">
                                                {`${paymentLabel(
                                                    expense.payment.method
                                                )}  #${
                                                    expense.payment.advanceMoney
                                                        ?.numberIdentifier || ''
                                                }`}
                                            </p>
                                        ) : (
                                            <p className="f16-500-dark">
                                                {paymentLabel(
                                                    expense.payment.method
                                                )}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                {expense.payment.creditCard && (
                                    <div className="label-value">
                                        <p className="f16-500-gray">
                                            Cartão utilizado
                                        </p>
                                        <div className="value">
                                            <p className="f16-500-dark">
                                                {expense.payment.creditCard
                                                    .label || '-'}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <div className="label-value">
                                    <p className="f16-500-gray">
                                        Método de cadastro
                                    </p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            {typeLabel(expense.type)}
                                        </p>
                                    </div>
                                </div>
                                {expense.receiptFile && (
                                    <div className="label-value">
                                        <p className="f16-500-gray">
                                            Recibo da despesa
                                        </p>
                                        <div className="value">
                                            <p className="f16-500-dark">
                                                <a
                                                    href={
                                                        expense.receiptFile.url
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <button
                                                        type="button"
                                                        className="history-btn"
                                                    >
                                                        Ver anexo
                                                    </button>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="content-block">
                            <p className="f16-500-gray italic">
                                Informações sobre a despesa
                            </p>
                            <div className="container">
                                <div className="label-value">
                                    <p className="f16-500-gray">
                                        Estabelecimento
                                    </p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            {expense.info.company?.name || '-'}
                                        </p>
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">Local</p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            {expense.info.company
                                                ?.addressLine || '-'}
                                        </p>
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">Data</p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            {expensesDateFormatter(
                                                new Date(
                                                    expense.datetime
                                                ).toDateString()
                                            )}
                                        </p>
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">
                                        Tipo de despesa
                                    </p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            <p className="f16-500-dark">
                                                {
                                                    expense.info.expenseCategory
                                                        .label
                                                }
                                            </p>
                                        </p>
                                    </div>
                                </div>
                                {expense.info.currency &&
                                    expense.info.currency.code !== 'BRL' && (
                                        <div className="label-value">
                                            <p className="f16-500-gray">
                                                Valor da despesa
                                            </p>
                                            <div className="value">
                                                <p className="f16-500-dark">{`${
                                                    expense.info.currency.code
                                                } ${priceFormatter(
                                                    expense.info.amount || 0
                                                )}`}</p>
                                            </div>
                                        </div>
                                    )}
                                <div className="label-value">
                                    {expense.info.currency.code !== 'BRL' ? (
                                        <LabelTooltip
                                            title="Valor da despesa em reais"
                                            info={`Este valor foi convertido de ${
                                                expense.info.currency.code
                                            } para BRL com uma taxa de conversão de ${
                                                (expense.info.amountInBRL ||
                                                    1) /
                                                (expense.info.amount || 1)
                                            }`}
                                        />
                                    ) : (
                                        <p className="f16-500-gray">
                                            Valor da despesa em reais
                                        </p>
                                    )}
                                    <div className="value">
                                        <p className="f16-500-dark">{`R$${priceFormatter(
                                            expense.info.amountInBRL ||
                                                expense.info.amount ||
                                                0
                                        )}`}</p>
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">Categoria</p>
                                    <div className="value">
                                        <p className="f16-500-dark">
                                            {expense.info.expenseCategory
                                                ?.label || ''}
                                        </p>
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">OCR</p>
                                    <div className="value">
                                        <button
                                            type="button"
                                            className="history-btn"
                                            onClick={() =>
                                                openOcrDataModal(
                                                    expense.ocrData
                                                )
                                            }
                                        >
                                            Ver mais
                                        </button>
                                    </div>
                                </div>
                                <div className="label-value">
                                    <p className="f16-500-gray">QRCode</p>
                                    <div className="value">
                                        <button
                                            type="button"
                                            className="history-btn"
                                            onClick={() =>
                                                openQrCodeDataModal(
                                                    expense.qrCodeData
                                                )
                                            }
                                        >
                                            Ver mais
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {expenseOutOfPolicyHandler(
                        expense.travelerClearanceEvaluation
                    ).isOutOfPolicy && (
                        <div className="comments">
                            <p className="f16-500-gray">
                                Quebra de política nos seguintes tópicos:
                            </p>
                            <div className="out-of-policy">
                                {expenseOutOfPolicyHandler(
                                    expense.travelerClearanceEvaluation
                                ).messages.map((message, index) => (
                                    <li
                                        className="out-of-policy-message"
                                        key={index}
                                    >
                                        {message}
                                    </li>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className="comments">
                        <p className="f16-500-gray">Informações gerenciais:</p>
                        <div className="management-info">
                            {expense.managementInfo &&
                            expense.managementInfo.length > 0 ? (
                                expense.managementInfo.map(
                                    (management, index) => (
                                        <div className="label-value-m">
                                            <p className="f16-500-dark">
                                                {management.label}
                                            </p>
                                            <div className="value flex-wrap">
                                                {management.options.map(
                                                    (option) => (
                                                        <p
                                                            className="f14-500-gray"
                                                            key={index}
                                                        >
                                                            {option.label}
                                                        </p>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )
                                )
                            ) : (
                                <p className="f14-500-gray">
                                    Sem informações gerencias vinculadas
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="comments">
                        <p className="f16-500-gray">Descrição</p>
                        <div className="description">
                            <p className="f16-500-gray">
                                {expense.observations}
                            </p>
                        </div>
                    </div>
                </ExpensesModalContainer>
            )}
            {showAllTravels && (
                <ListMembers
                    type="TRAVELERS_LIST"
                    isOpen={showAllTravels}
                    onRequestClose={() => {
                        setShowAllTravels(false)
                    }}
                    usersAdded={expense.travelers.map((traveler) => ({
                        id: traveler.id,
                        label: `${traveler.firstName} ${traveler.lastName}`,
                        url: traveler.photoUrl?.url || undefined,
                    }))}
                />
            )}
        </ModalContainer>
    )
}
