/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR'
import {
    CarReportUi,
    ExpenseReport,
    FlightReportUi,
    GeneralReportUi,
    HotelReportUi,
} from '../../interfaces/Reports'
import { SingleReportDataContainer } from './styles'
import { priceFormatter } from '../../utils/expensnes/priceFormatter'
import { expenseStatusLabel } from '../../utils/expensnes/expenseStatusLabel'

export interface Props {
    generalReport?: GeneralReportUi
    flightReport?: FlightReportUi
    hotelReport?: HotelReportUi
    carReport?: CarReportUi
    expensesReport?: ExpenseReport
}

function productTypeLabel(type: string) {
    if (type === 'FLIGHT') return 'Voo'
    if (type === 'CAR') return 'Carro'
    if (type === 'HOTEL') return 'Hotel'
    return null
}

export function SingleReportData({
    hotelReport,
    generalReport,
    flightReport,
    carReport,
    expensesReport,
}: Props) {
    if (hotelReport) {
        return (
            <SingleReportDataContainer>
                <div className="item start">
                    <h2>{hotelReport.traveler}</h2>
                </div>
                <div className="item">
                    <h2>
                        {format(
                            new Date(hotelReport.issuedAt || 0),
                            `dd'/'MM'/'yyyy`,
                            {
                                locale: ptLocale,
                            }
                        )}
                    </h2>
                </div>
                <div className="item">
                    <h2>{hotelReport.recordLocator}</h2>
                </div>
                <div className="item center">
                    <h2>
                        {hotelReport.city}
                        {hotelReport.state ? `- ${hotelReport.state}` : ''}
                    </h2>
                </div>
                <div className="item">
                    <h2>
                        {' '}
                        {format(
                            new Date(hotelReport.checkinDatetime || 0),
                            `dd'/'MM'/'yyyy`,
                            {
                                locale: ptLocale,
                            }
                        )}
                    </h2>
                </div>
                <div className="item">
                    <h2>
                        {' '}
                        {format(
                            new Date(hotelReport.checkoutDatetime || 0),
                            `dd'/'MM'/'yyyy`,
                            {
                                locale: ptLocale,
                            }
                        )}
                    </h2>
                </div>
            </SingleReportDataContainer>
        )
    }

    if (flightReport) {
        return (
            <SingleReportDataContainer>
                <div className="item">
                    <h2>{flightReport.traveler}</h2>
                </div>
                <div className="item">
                    <h2>
                        {format(
                            new Date(flightReport.issuedAt || 0),
                            `dd'/'MM'/'yyyy`,
                            {
                                locale: ptLocale,
                            }
                        )}
                    </h2>
                </div>
                <div className="item">
                    <h2>
                        {format(
                            new Date(flightReport.startDate || 0),
                            `dd'/'MM'/'yyyy`,
                            {
                                locale: ptLocale,
                            }
                        )}
                    </h2>
                </div>
                <div className="item">
                    <h2>
                        {flightReport.recordLocator ||
                            flightReport.ticketNumber}
                    </h2>
                </div>
                <div className="item center">
                    <h2>{flightReport.journeys}</h2>
                </div>
                <div className="item">
                    <h2>{flightReport.seller}</h2>
                </div>
            </SingleReportDataContainer>
        )
    }

    if (generalReport) {
        return (
            <SingleReportDataContainer>
                <div className="item">
                    <h2 className="start">{generalReport.traveler}</h2>
                </div>
                <div className="item">
                    <h2>
                        {format(
                            new Date(generalReport.issuedAt || 0),
                            `dd'/'MM'/'yyyy`,
                            {
                                locale: ptLocale,
                            }
                        )}
                    </h2>
                </div>
                <div className="item">
                    <h2>{productTypeLabel(generalReport.productType)}</h2>
                </div>
                <div className="item">
                    <h2>{generalReport.recordLocator || '-'}</h2>
                </div>
                <div className="item">
                    <h2>{generalReport.status}</h2>
                </div>
                <div className="item">
                    <h2>{generalReport.location || '-'}</h2>
                </div>
            </SingleReportDataContainer>
        )
    }

    if (carReport) {
        return (
            <SingleReportDataContainer
                style={{ gridTemplateColumns: '4fr 2fr 3fr 3fr 2fr 2fr' }}
            >
                <div className="item">
                    <h2>{carReport.traveler}</h2>
                </div>
                <div className="item">
                    <h2>
                        {format(
                            new Date(carReport.issuedAt || 0),
                            `dd'/'MM'/'yyyy`,
                            {
                                locale: ptLocale,
                            }
                        )}
                    </h2>
                </div>
                <div className="item">
                    <h2>{carReport.recordLocator}</h2>
                </div>
                <div className="item">
                    <h2>{carReport.pickupLocation}</h2>
                </div>
                <div className="item">
                    <h2>
                        {format(
                            new Date(carReport.pickupDatetime || 0),
                            `dd'/'MM'/'yyyy`,
                            {
                                locale: ptLocale,
                            }
                        )}
                    </h2>
                </div>
                <div className="item">
                    <h2>
                        {format(
                            new Date(carReport.returnDatetime || 0),
                            `dd'/'MM'/'yyyy`,
                            {
                                locale: ptLocale,
                            }
                        )}
                    </h2>
                </div>
            </SingleReportDataContainer>
        )
    }
    if (expensesReport) {
        return (
            <SingleReportDataContainer
                style={{ gridTemplateColumns: '1fr 4fr 3fr 2fr 3fr 2fr' }}
            >
                <div className="item">
                    <h2>#{expensesReport.numberIdentifier}</h2>
                </div>

                <div className="item">
                    <h2>{expensesReport.creatorName}</h2>
                </div>
                <div className="item">
                    <h2>
                        {format(
                            new Date(expensesReport.datetime || 0),
                            `dd'/'MM'/'yyyy`,
                            {
                                locale: ptLocale,
                            }
                        )}
                    </h2>
                </div>
                <div className="item">
                    <h2>{expensesReport.expenseCategoryLabel}</h2>
                </div>
                <div className="item">
                    <h2>{expenseStatusLabel(expensesReport.status)}</h2>
                </div>
                <div className="item">
                    <h2>R${priceFormatter(expensesReport.totalAmount || 0)}</h2>
                </div>
            </SingleReportDataContainer>
        )
    }

    return null
}
