/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
import React from 'react'
import { IconBaseProps } from 'react-icons'
import { InputContainer, InputContent } from './styles'

interface Props {
    label: string
    icon?: React.ComponentType<IconBaseProps>
    normalLabel?: boolean
    value: string | undefined
    placeHolder: string
    callback(): void
}

export function FilterInputBtn({
    normalLabel,
    icon: Icon,
    label,
    value,
    placeHolder,
    callback,
}: Props) {
    return (
        <InputContainer>
            {normalLabel && (
                <h2 className="f14-500-gray normal-label">{label}</h2>
            )}
            <InputContent onClick={() => callback()}>
                {/* @ts-ignore */}
                {Icon && <Icon />} {value || placeHolder}
            </InputContent>
        </InputContainer>
    )
}
