/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react'
import ModalContainer from 'react-modal'
import { ExpensesModalContainer } from './styles'
import { Expenses } from '../../interfaces/expenses'
import { useExpenses } from '../../hooks/use-expenses'
import { ListMembersViewer } from '../ListMembersViewer '

interface Props {
    isOpen: boolean
    onRequestClose: () => void
    approvalStages: Expenses['approvalStages']
}

export default function ExpensesApprovalStagesModal({
    isOpen,
    onRequestClose,
    approvalStages,
}: Props) {
    const { openModal, getApproversOfExpense, approversOfExpenses } =
        useExpenses()
    const [openUsersModal, setOpenUsersModal] = useState<
        { id: string; label: string; url?: string | undefined }[] | undefined
    >()
    const [busy, setBusy] = useState(true)

    console.log(openModal)

    useEffect(() => {
        ;(async () => {
            if (openModal) {
                await getApproversOfExpense(openModal.id)
                setBusy(false)
            }
        })()
    }, [])

    function firstApproverName(level: number) {
        if (!approversOfExpenses || !approversOfExpenses.length) return '-'
        const approvers = approversOfExpenses.find(
            (approver) => approver.level === level
        )?.users
        if (approvers) {
            const firstApprover = `${approvers[0].firstName} ${approvers[0].lastName}`
            const plusApprovers = `${
                approvers.length > 1 ? `+ ${approvers.length - 1}` : ''
            }`
            return `${firstApprover} ${plusApprovers}`
        }
        return '-'
    }

    function allApprovers(level: number) {
        if (!level) return []
        const approvers = approversOfExpenses.find(
            (approver) => approver.level === level
        )?.users
        if (!approvers) return []
        return approvers
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="react-modal-overlay"
            className="react-modal-stages"
        >
            <ExpensesModalContainer>
                {!busy && (
                    <div className="content-block">
                        <p className="f16-500-gray italic">
                            Estágios de aprovação
                        </p>
                        <div className="container">
                            {approvalStages &&
                                approvalStages.length > 0 &&
                                approvalStages?.map((item, index) => {
                                    return (
                                        <div
                                            className="label-value"
                                            key={index}
                                        >
                                            <p className="f16-500-gray">
                                                {item.approved
                                                    ? `Aprovado no nível ${item.level} por ${item.approver.firstName} ${item.approver.lastName}`
                                                    : `Não aprovado no nível ${item.level}`}
                                            </p>
                                            {!item.approved && (
                                                <div className="all-approvers">
                                                    <p className="f14-500-gray">
                                                        {`Aprovadores: ${firstApproverName(
                                                            item.level
                                                        )} `}
                                                    </p>
                                                    {allApprovers(item.level)
                                                        .length > 1 && (
                                                        <button
                                                            type="button"
                                                            className="all-approvers-btn"
                                                            onClick={() =>
                                                                setOpenUsersModal(
                                                                    allApprovers(
                                                                        item.level
                                                                    )?.map(
                                                                        (
                                                                            approver
                                                                        ) => ({
                                                                            id: approver.id,
                                                                            label: `${approver.firstName} ${approver.lastName}`,
                                                                            url: approver.photoUrl
                                                                                ? approver.photoUrl
                                                                                : undefined,
                                                                        })
                                                                    )
                                                                )
                                                            }
                                                        >
                                                            Ver todos
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                )}
            </ExpensesModalContainer>
            {openUsersModal && (
                <ListMembersViewer
                    label="Aprovadores"
                    isOpen={!!openUsersModal}
                    onRequestClose={() => setOpenUsersModal(undefined)}
                    users={openUsersModal}
                />
            )}
        </ModalContainer>
    )
}
